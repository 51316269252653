.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .logo-image {
    max-width: 100%;
    height: auto;
  }
  
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .info-box {
    background-color: #f9f9f9;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .info-box h2 {
    margin-bottom: 10px;
  }
  
  .info-box p {
    margin: 0;
  }
  