.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.table-container, .details-table-container {
  background-color: #f8f9fa; /* Fondo gris claro */
  padding: 20px;
  border-radius: 8px;
}
.top-table-container{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.details-table-container{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.table {
  background-color: #ffffff; /* Fondo blanco */
}

.table th,
.table td {
  vertical-align: middle;
  text-align: center;
}

.table .btn {
  margin: 0 auto;
}

.pagination {
  justify-content: center;
  margin-top: 20px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.btn-secondary {
  background-color: #ffcccc; /* Tono rojo claro */
  border-color: #ffcccc;
}

.btn-secondary:hover {
  background-color: #ff9999;
  border-color: #ff9999;
}
