.custom-navbar {
  margin-bottom: 20px;
}

.custom-nav {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #e0e0e0; /* Línea gris más suave */
  padding-bottom: 5px; /* Reduce el espacio debajo de los enlaces de navegación */
}

.custom-nav .nav-link {
  color: #333;
  margin-right: 15px;
  text-align: center;
  font-size: 0.9em; /* Reduce el tamaño del texto */
  padding: 5px 10px; /* Alinea verticalmente los elementos */
}

.custom-nav .nav-link:last-child {
  border-right: none; /* Elimina la línea del último botón */
}

.custom-nav .nav-link:hover {
  color: #007bff;
}

.active-nav-link {
  font-weight: bold;
  color: #0056b3 !important;
}

.d-lg-none.navbar-brand {
  margin-left: 24px;
}
